<template>
   <div class="container">
      <el-container class="wrap">
        <el-main class="mains">
          <div class="main_content">
            <div class="testtime">
                <div class="end" @click="changeAnswer('pre')">上一题</div>
                <div class="flex-1 ">
                  <div class="daojishi">
                    <span class="font-30 text-bold c-title2">{{timeFormat(supleTime)}}</span>
                    <span>本题剩余时间</span>
                  </div>
                </div>
                <div class="submit" @click="changeAnswer('next')">下一题</div>
            </div>
            <!-- 阅读选择 -->
            <yue-du-xuan-ze :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '阅读选择'" :detail="true"></yue-du-xuan-ze>
            <!-- 补全单词 -->
            <bu-quan-dan-ci :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '补全单词'" :detail="true"></bu-quan-dan-ci>
            <!-- 听写句子 -->
            <ting-xie-ju-zi :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '听写句子'" :detail="true"></ting-xie-ju-zi>
            <!-- 互动听力 -->
            <hu-dong-ting-li :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '互动听力'" :detail="true"></hu-dong-ting-li>
            <!-- 朗读句子 -->
            <lang-du-ju-zi :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '朗读句子'" :detail="true"></lang-du-ju-zi>
            <!-- 听题演讲 -->
            <ting-ti-yan-jiang :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '听题演讲'" :detail="true"></ting-ti-yan-jiang>
            <!-- 看图说话 -->
            <kan-tu-shuo-hua :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '看图说话'" :detail="true"></kan-tu-shuo-hua>
            <!-- 阅读说话 -->
            <yue-du-shuo-hua :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '阅读说话'" :detail="true"></yue-du-shuo-hua>
            <!-- 口语样本 -->
            <kou-yu-yang-ben :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '口语样本'" :detail="true"></kou-yu-yang-ben>
            <!-- 互动阅读 -->
            <hu-dong-yue-du :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '互动阅读'" :detail="true" ref="hudongyuedu"></hu-dong-yue-du>
            <!-- 阅读填空 -->
            <yue-du-tian-kong :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '阅读填空'" :detail="true" ref="yuedutiankong"></yue-du-tian-kong>
            <!-- 看图写作 -->
            <kan-tu-xie-zuo :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '看图写作'" :detail="true"></kan-tu-xie-zuo>
            <!-- 写作样本 -->
            <xie-zuo-yang-ben :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '写作样本'" :detail="true"></xie-zuo-yang-ben>
            <!-- 互动写作 -->
            <hu-dong-xie-zuo :questionDetail="questionDetail"  v-if="questionDetail.topic_type_name == '互动写作'" :detail="true"></hu-dong-xie-zuo>
          </div>
        </el-main>
      </el-container>
   </div>
</template>
<script>
import BuQuanDanCi from '../components/BuQuanDanCi.vue';
import HuDongTingLi from '../components/HuDongTingLi.vue';
import HuDongXieZuo from '../components/HuDongXieZuo.vue';
import HuDongYueDu from '../components/HuDongYueDu.vue';
import KanTuShuoHua from '../components/KanTuShuoHua.vue';
import KanTuXieZuo from '../components/KanTuXieZuo.vue';
import KouYuYangBen from '../components/KouYuYangBen.vue';
import LangDuJuZi from '../components/LangDuJuZi.vue';
import TingTiYanJiang from '../components/TingTiYanJiang.vue';
import TingXieJuZi from '../components/TingXieJuZi.vue';
import XieZuoYangBen from '../components/XieZuoYangBen.vue';
import YueDuShuoHua from '../components/YueDuShuoHua.vue';
import YueDuTianKong from '../components/YueDuTianKong.vue';
import YueDuXuanZe from '../components/YueDuXuanZe.vue';
export default {
  components: {
    YueDuXuanZe,
    BuQuanDanCi,
    TingXieJuZi,
    HuDongTingLi,
    LangDuJuZi,
    TingTiYanJiang,
    YueDuShuoHua,
    KouYuYangBen,
    HuDongYueDu,
    YueDuTianKong,
    KanTuXieZuo,
    XieZuoYangBen,
    HuDongXieZuo,
    KanTuShuoHua
  },
   data() {
      return {
         // 考试卷列表
          examList: [],
          current: 0,
          //考卷信息
          log_id: '',
          questionDetail: {
            topic_type_name: ""
          },
          id: '',
          supleTime: 0,
      }
   },
   created() {
    this.id = this.$route.query.id;
   },
   mounted() {
     this.getExamList()
   },
   methods: {
     changeAnswer(e) {
        if(e == 'next') { //下一题
          this.current++;
        }else { //上一题
          this.current--;
        }
        //初始化数据
        this.getExamDetail();
     },
     timeFormat: function (time) {
          if (!time) return '00:00'
          let min = parseInt(time / 60);
          let sec = parseInt(time) % 60;
          if (min < 10) min = '0' + min
          if (sec < 10) sec = '0' + sec
          return (min + ':' + sec)
      },
      getExamList() {
        this.$api.getMockRecordInfo({
          id: this.id
        }).then(res => {
          if(res.code == 1) {
              this.examList = res.data.list;
              this.log_id = res.data.log_id;
              //默认赋值第一道题
              if(res.data.list.length > 0) {
                this.getExamDetail();
              }
            }
        })
      },
      getExamDetail() {
        this.$api.getMockRecordDetail({
          id: this.examList[this.current].id,
          type: this.examList[this.current].type,
          log_id: this.log_id,
        }).then(res => {
          if(res.code == 1) {
              this.questionDetail = res.data;
              //计算剩余时间
              var remain_time = this.questionDetail.user_time;
              var total_time = this.questionDetail.audio_time;
              this.supleTime = parseFloat(total_time) - parseFloat(remain_time);
              
              if(this.questionDetail.topic_type_name == '阅读填空') {
                this.$nextTick(() => {
                  this.$refs.yuedutiankong.calcData();
                })
              }
              if(this.questionDetail.topic_type_name == '互动阅读') {
                this.$nextTick(() => {
                  this.$refs.hudongyuedu.calcData();
                  this.$refs.hudongyuedu.currentindex = 0;
                })
              }

            }
        })
      },
   }
}
</script>
<style  lang="scss" scoped>
.testtime {
  display: flex;
  align-items: end;
  margin: 0 20px 0;
  border-bottom: 1px solid rgba(28, 39, 76, 0.1);
  box-sizing: border-box;
  width: calc(100% - 40px);
  padding-bottom: 30px;
  .end {
    width: 129px;
    height: 45px;
    font-size: 14px;
    line-height: 45px;
    text-align: center;
    border-radius: 2px;
    border: 1px solid #DDDFE4;
    cursor: pointer;
  }
  .daojishi {
    width: 508px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .submit {
    width: 129px;
    height: 45px;
    font-size: 14px;
    line-height: 45px;
    text-align: center;
    border-radius: 2px;
    color: #fff;
    background: $color-title2;
    cursor: pointer;
  }
}

.container,.wrap {
  width: 100%;
  height: 100vh;
  background: #F6F7FB;
}
.mains {
  height: calc(100% - 70px);
  padding-top: 62px;
  box-sizing: border-box;
}

.main_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  border-radius: 2px;
  padding: 30px 0;
  // img {
  //   width: 148px;
  //   height: 156px;
  // }
  // .score {
  //   display: inline-block;
  //   padding: 0 24px;
  //   background: #F6F7FB;
  //   font-size: 30px; 
  //   font-weight: bold;
  //   color: $color-title2;
  // }
  // .confirm {
  //   width: 110px;
  //   height: 38px;
  //   line-height: 38px;
  //   text-align: center;
  //   border-radius: 2px;
  //   background: $color-title2;
  //   color: #fff;
  //   font-size: 14px;
  // }
}
</style>
